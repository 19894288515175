<template>
  <div class="top-category">
      TopCategory
  </div>
</template>

<script>
export default {
  name:'TopCategory',
  setup() {
  }
}
</script>

<style lang="less" scoped>

</style>
